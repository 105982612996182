import { SupportedCurrencyCodes } from "../hooks/useCurrency";

export const getPriceForCurrency = (currencyCode: SupportedCurrencyCodes) => {
  if (currencyCode === SupportedCurrencyCodes.USD) return "$39";
  if (currencyCode === SupportedCurrencyCodes.GBP) return "£34";
  if (currencyCode === SupportedCurrencyCodes.EUR) return "€38";
  if (currencyCode === SupportedCurrencyCodes.BRL) return "R$179";
  if (currencyCode === SupportedCurrencyCodes.AUD) return "A$64";
  if (currencyCode === SupportedCurrencyCodes.CAD) return "CA$52";
  if (currencyCode === SupportedCurrencyCodes.DKK) return "DKK 294";
  if (currencyCode === SupportedCurrencyCodes.NOK) return "NOK 445";
  if (currencyCode === SupportedCurrencyCodes.SEK) return "SEK 445";
};

export const getGoldTierPriceForCurrency = (
  currencyCode: SupportedCurrencyCodes
) => {
  if (currencyCode === SupportedCurrencyCodes.USD) return "$69";
  if (currencyCode === SupportedCurrencyCodes.GBP) return "£54";
  if (currencyCode === SupportedCurrencyCodes.EUR) return "€59";
  if (currencyCode === SupportedCurrencyCodes.BRL) return "R$299";
  if (currencyCode === SupportedCurrencyCodes.AUD) return "A$99";
  if (currencyCode === SupportedCurrencyCodes.CAD) return "CA$92";
  if (currencyCode === SupportedCurrencyCodes.DKK) return "DKK 468";
  if (currencyCode === SupportedCurrencyCodes.NOK) return "NOK 699";
  if (currencyCode === SupportedCurrencyCodes.SEK) return "SEK 699";
};

export const priceAndCurrencyMap = {
  silver: {
    [SupportedCurrencyCodes.USD]: {
      amount: 39,
      currencySymbol: "$",
    },
    [SupportedCurrencyCodes.GBP]: {
      amount: 34,
      currencySymbol: "£",
    },
    [SupportedCurrencyCodes.EUR]: {
      amount: 38,
      currencySymbol: "€",
    },
    [SupportedCurrencyCodes.BRL]: {
      amount: 179,
      currencySymbol: "R$",
    },
    [SupportedCurrencyCodes.AUD]: {
      amount: 64,
      currencySymbol: "A$",
    },
    [SupportedCurrencyCodes.CAD]: {
      amount: 52,
      currencySymbol: "CA$",
    },
    [SupportedCurrencyCodes.DKK]: {
      amount: 294,
      currencySymbol: "DKK ",
    },
    [SupportedCurrencyCodes.NOK]: {
      amount: 445,
      currencySymbol: "NOK ",
    },
    [SupportedCurrencyCodes.SEK]: {
      amount: 445,
      currencySymbol: "SEK ",
    },
  },
  gold: {
    [SupportedCurrencyCodes.USD]: {
      amount: 69,
      currencySymbol: "$",
    },
    [SupportedCurrencyCodes.GBP]: {
      amount: 54,
      currencySymbol: "£",
    },
    [SupportedCurrencyCodes.EUR]: {
      amount: 59,
      currencySymbol: "€",
    },
    [SupportedCurrencyCodes.BRL]: {
      amount: 299,
      currencySymbol: "R$",
    },
    [SupportedCurrencyCodes.AUD]: {
      amount: 99,
      currencySymbol: "A$",
    },
    [SupportedCurrencyCodes.CAD]: {
      amount: 92,
      currencySymbol: "CA$",
    },
    [SupportedCurrencyCodes.DKK]: {
      amount: 468,
      currencySymbol: "DKK ",
    },
    [SupportedCurrencyCodes.NOK]: {
      amount: 699,
      currencySymbol: "NOK ",
    },
    [SupportedCurrencyCodes.SEK]: {
      amount: 699,
      currencySymbol: "SEK ",
    },
  },
};
