import { useEffect, useState } from "react";
import { env } from "../env.mjs";

interface IPStackResponse {
  currency: {
    code: string;
  };
}

export const SupportedCurrencyCodes = {
  GBP: "GBP",
  USD: "USD",
  EUR: "EUR",
  BRL: "BRL",
  AUD: "AUD",
  CAD: "CAD",
  DKK: "DKK",
  NOK: "NOK",
  SEK: "SEK",
} as const;

export type SupportedCurrencyCodes =
  (typeof SupportedCurrencyCodes)[keyof typeof SupportedCurrencyCodes];

export const useCurrency = () => {
  const [currencyCode, setCurrencyCode] = useState<SupportedCurrencyCodes>();

  useEffect(() => {
    const getUserInfo = async () => {
      const response = await fetch(
        `https://api.ipgeolocation.io/ipgeo?apiKey=${env.NEXT_PUBLIC_IPGEOLOCATION_API_KEY}`
      );

      const data = (await response.json()) as IPStackResponse;

      if (!data.currency) return setCurrencyCode(SupportedCurrencyCodes.USD);

      switch (data.currency.code) {
        case SupportedCurrencyCodes.GBP:
          setCurrencyCode(SupportedCurrencyCodes.GBP);
          break;
        case SupportedCurrencyCodes.USD:
          setCurrencyCode(SupportedCurrencyCodes.USD);
          break;
        case SupportedCurrencyCodes.EUR:
          setCurrencyCode(SupportedCurrencyCodes.EUR);
          break;
        case SupportedCurrencyCodes.BRL:
          setCurrencyCode(SupportedCurrencyCodes.BRL);
          break;
        case SupportedCurrencyCodes.AUD:
          setCurrencyCode(SupportedCurrencyCodes.AUD);
          break;
        case SupportedCurrencyCodes.CAD:
          setCurrencyCode(SupportedCurrencyCodes.CAD);
          break;
        case SupportedCurrencyCodes.DKK:
          setCurrencyCode(SupportedCurrencyCodes.DKK);
          break;
        case SupportedCurrencyCodes.NOK:
          setCurrencyCode(SupportedCurrencyCodes.NOK);
          break;
        case SupportedCurrencyCodes.SEK:
          setCurrencyCode(SupportedCurrencyCodes.SEK);
          break;
        default:
          setCurrencyCode(SupportedCurrencyCodes.USD);
          break;
      }
    };

    getUserInfo().catch((e) => {
      console.error("Failed to fetch user IP info", e);
      setCurrencyCode(SupportedCurrencyCodes.USD);
    });
  }, []);

  return currencyCode;
};
