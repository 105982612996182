import React, { useState, useEffect } from "react";
import { useEventsStore } from "../../store/store";
import {
  isMobile,
  isIOS,
  isIOS13,
  isIPad13,
  isIPhone13,
  isIPod13,
  isAndroid,
  isSafari,
} from "react-device-detect";

import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../ui/dialog";
import {
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from "../ui/drawer";
import Image from "next/image";

type BeforeInstallPromptEvent = Event & {
  prompt: () => Promise<void>;
};

export const PWAInstallPrompt = ({ trigger }: { trigger: React.ReactNode }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [canInstall, setCanInstall] = useState(false);
  const [deferredPrompt, setDeferredPrompt] =
    useState<BeforeInstallPromptEvent | null>(null);
  const isStandalonePWA = useEventsStore((state) => state.isStandalonePWA);

  const isApple = isIOS || isIOS13 || isIPad13 || isIPhone13 || isIPod13;

  useEffect(() => {
    const handleBeforeInstallPrompt = (e: BeforeInstallPromptEvent) => {
      e.preventDefault();
      setDeferredPrompt(e);
      setCanInstall(true);
    };

    window.addEventListener("beforeinstallprompt", (e: Event) => {
      handleBeforeInstallPrompt(e as BeforeInstallPromptEvent);
    });

    return () => {
      window.removeEventListener("beforeinstallprompt", (e: Event) => {
        handleBeforeInstallPrompt(e as BeforeInstallPromptEvent);
      });
    };
  }, []);

  const handleInstall = async () => {
    if (deferredPrompt) {
      setIsDrawerOpen(false);
      await deferredPrompt.prompt();
      /**
       * You can only call prompt() on the deferred event once.
       * If the user dismisses it, you need to wait until the beforeinstallprompt event is fired again.
       */
      setCanInstall(false);
      setDeferredPrompt(null);
    }
  };

  const renderInstallInstructions = () => {
    if (isApple && isSafari) {
      return (
        <div>
          <ol>
            <li className="my-6">
              1. Tap{" "}
              <span className="mx-2 inline-flex items-center justify-center rounded-sm border border-neutral-700 bg-neutral-800 p-0.5">
                <Image
                  src="/images/ios-share-apple.svg"
                  alt="IOS share icon"
                  width={24}
                  height={24}
                  className="inline-block"
                  style={{
                    objectFit: "contain",
                  }}
                />
              </span>{" "}
              in the browser menu
            </li>
            <li className="my-6">
              {`2. Scroll down and tap`}{" "}
              <span className="ml-1 rounded-sm border border-neutral-700 bg-neutral-800 p-1 px-2 text-sm">
                Add to Home Screen
              </span>
            </li>
            <li className="my-6 ">
              3. Look for the
              <span className="mx-2 inline-flex items-center justify-center rounded-sm border border-neutral-700 bg-neutral-800 p-0.5">
                <Image
                  src="/icons/pod-192-icon.png"
                  alt="Pinnacle Odds Dropper logo"
                  width={24}
                  height={24}
                  className="inline-block bg-blue-200 text-blue-400"
                  style={{
                    objectFit: "contain",
                  }}
                />
              </span>
              icon on your home screen
            </li>
          </ol>
        </div>
      );
    } else if (isApple && !isSafari) {
      return (
        <div>
          <ol>
            <li className="my-6">
              1. Open the website in Safari{" "}
              <span className="mx-2 inline-flex items-center justify-center rounded-sm border border-neutral-700 bg-neutral-800 p-0.5">
                <Image
                  src="/images/safari-icon.png"
                  alt="Safari browser icon"
                  width={24}
                  height={24}
                  className="inline-block"
                  style={{
                    objectFit: "contain",
                  }}
                />
              </span>
            </li>
            <li className="my-6">
              2. Tap{" "}
              <span className="mx-2 inline-flex items-center justify-center rounded-sm border border-neutral-700 bg-neutral-800 p-0.5">
                <Image
                  src="/images/ios-share-apple.svg"
                  alt="IOS share icon"
                  width={24}
                  height={24}
                  className="inline-block"
                  style={{
                    objectFit: "contain",
                  }}
                />
              </span>{" "}
              in the browser menu
            </li>
            <li className="my-6">
              {`3. Scroll down and tap`}{" "}
              <span className="ml-1 rounded-sm border border-neutral-700 bg-neutral-800 p-1 px-2 text-sm">
                Add to Home Screen
              </span>
            </li>
            <li className="my-6">
              4. Look for the
              <span className="mx-2 inline-flex items-center justify-center rounded-sm border border-neutral-700 bg-neutral-800 p-0.5">
                <Image
                  src="/icons/pod-192-icon.png"
                  alt="Pinnacle Odds Dropper logo"
                  width={24}
                  height={24}
                  className="inline-block bg-blue-200 text-blue-400"
                  style={{
                    objectFit: "contain",
                  }}
                />
              </span>
              icon on your home screen
            </li>
          </ol>
        </div>
      );
    } else if (isAndroid) {
      return (
        <div>
          <ol>
            <li className="my-6">{`1. Tap the menu button (usually represented by three vertical dots and located in the top right corner)`}</li>
            <li className="my-6">
              {`2. Tap`}{" "}
              <span className="ml-1 rounded-sm border border-neutral-700 bg-neutral-800 p-1 px-2 text-sm">
                Add to Home screen
              </span>
            </li>
            <li className="my-6">
              {`2. Tap`}{" "}
              <span className="mx-1 rounded-sm border border-neutral-700 bg-neutral-800 p-1 px-2 text-sm">
                Add
              </span>{" "}
              when prompted
            </li>
          </ol>
        </div>
      );
    }
    return (
      <div>
        <p className="text-amber-300">
          Instructions on how to install the app on your device are missing.
          Please contact henry@pinnacleoddsdropper.com for help.
        </p>
      </div>
    );
  };

  if (isStandalonePWA) {
    return null;
  }

  if (!isMobile) {
    return (
      <Dialog>
        <DialogTrigger asChild>{trigger}</DialogTrigger>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Install the app</DialogTitle>
          </DialogHeader>
          <div className="flex flex-col items-center">
            <p className="my-4">
              Scan the QR code below to install the app on your iPhone or
              Android smartphone.
            </p>
            <Image
              src="/images/qr-code-pod.png"
              alt="QR code"
              width={200}
              height={200}
              className="my-4"
            />
          </div>
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Drawer open={isDrawerOpen} onOpenChange={setIsDrawerOpen}>
      <DrawerTrigger asChild>{trigger}</DrawerTrigger>
      <DrawerContent className="p-6 pt-2">
        <DrawerHeader className="pl-0 text-left">
          <DrawerTitle className="pt-4 font-semibold text-neutral-300">
            Install the app
          </DrawerTitle>
        </DrawerHeader>
        <div className="mb-4 mt-2 flex items-center gap-4 rounded-sm border border-neutral-700 bg-neutral-800 p-4">
          <Image
            src="/icons/pod-192-icon.png"
            alt="Pinnacle Odds Dropper logo"
            width={40}
            height={40}
            className="shrink-0"
          />
          <div>
            <p>Pinnacle Odds Dropper</p>
            <p>www.pinnacleoddsdropper.com</p>
          </div>
        </div>
        {canInstall ? (
          <button
            onClick={handleInstall}
            className="my-4 rounded-sm bg-blue-600 px-4 py-2 text-white hover:bg-blue-700"
          >
            Install POD
          </button>
        ) : (
          renderInstallInstructions()
        )}
      </DrawerContent>
    </Drawer>
  );
};
